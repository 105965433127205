import StudentContext from './StudentContext'
import React, { useState, useEffect } from 'react'

const StudentState = (props) => {
  const host = process.env.REACT_APP_BACKEND_URL;
  const studentsd = []

  const [students, setStudents] = useState(studentsd)
  // Attendance Data for “today” (or however you store it)
  const [attendanceData, setAttendanceData] = useState([]);


  // ====== NEW PART: filterCounts state ======
  const [filterCounts, setFilterCounts] = useState({
    all: 0,
    active: 0,
    inactive: 0,
    hasBooking: 0,
    expired: 0,
    online: 0,
    offline: 0,
    absent: 0,
  });

  
  // ============== Existing methods ==============

  // Get all students
  const getAllStudents = async () => {
    try {
      const response = await fetch(`${host}/students/showall/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem('token'), // Replace with your actual auth token
        },
      });
      const json = await response.json();
      setStudents(json.reverse());
    } catch (error) {
      console.error("Error fetching all students:", error);
      // Handle the error, you might want to set an error state or display a message to the user
    }
  };

    // ============== Fetch today’s attendance data ==============
  // (Adjust URL and logic based on your backend.)
  const fetchAttendanceData = async () => {
    try {
      const response = await fetch(`${host}/attendance/attendance-today`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'auth-token': localStorage.getItem('token'),
        }
      });
      const data = await response.json();
      if (response.ok) {
        setAttendanceData(data.data); // data.data might be your array of records
      } else {
        console.error("Failed to fetch attendance:", data.error);
      }
    } catch (error) {
      console.error("Error fetching attendance data:", error);
    }
  };



  // get one studentprofile
  const getOneStudent = async () => {
    try {
      const response = await fetch(`${host}/students/student-data/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem('token'), // Replace with your actual auth token
        },
      });
      const studentsData = await response.json();
      return studentsData;
    } catch (error) {
      console.error("Error fetching all students:", error);
      // Handle the error, you might want to set an error state or display a message to the user
    }
  };


  // Add students

  const addStudent = async (name, email, gender, password, address, phone, parentsphone, photo, documentid, role) => {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('gender', gender);
    formData.append('password', password);
    formData.append('address', address);
    formData.append('phone', phone);
    formData.append('parentsphone', parentsphone);

    // Check if photo and documentid are files and append them if they are
    if (photo instanceof File) {
      formData.append('photo', photo, photo.name);
    }
    if (documentid instanceof File) {
      formData.append('documentid', documentid, documentid.name);
    }

    formData.append('role', role);

    try {
      const response = await fetch(`${host}/students/create/`, {
        method: "POST",
        body: formData, // No headers needed, FormData is automatically detected
      });

      const json = await response.json();

      if (!response.ok) {
        // console.log(response, 'hello res')
        throw new Error(`Error: ${json.error}`);
      }

      // Since we cannot directly get the photo and documentid paths from the response here,
      // assuming the server response includes the new student data with file paths
      const student = json.newStudents; // Adjust based on actual response structure
      const attendanceCode = json.attendanceCode;

      if (!attendanceCode) {
        throw new Error("Attendance Code is missing from response!");
      }
      student.attendanceCode = attendanceCode;
      setStudents(prevStudents => [...prevStudents, student]);

      return {
        success: true,
        student,
        attendanceCode
      };

    } catch (error) {
      console.error("Error adding student:", error);
      return { success: false, message: error.message };
    }
  }


  // Add a student copy
  // const addStudent = async (name, email, gender, password, address, phone, parentsphone, photo, documentid, role) => {
  //   try {
  //     const response = await fetch(`${host}/students/create/`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({ name, email, gender, password, address, phone, parentsphone, photo, documentid, role }),
  //     });
  //     if (!response.ok) { // Check if the response status code is not successful
  //       throw new Error(`HTTP error! status: ${response.status}`); // Throw an error with the status
  //     }
  //     const json = await response.json();

  //     const student = {
  //       "name": name,
  //       "email": email,
  //       "gender": gender,
  //       "password": password,
  //       "address": address,
  //       "phone": phone,
  //       "parentsphone": parentsphone,
  //       "photo": photo,
  //       "documentid": documentid,
  //       "role": role,
  //     };
  //     setStudents([...students, student]);
  //     return response;
  //   } catch (error) {
  //     console.error("Error adding student:", error);
  //     return { success: false, message: error.message };
  //   }
  // }

  // Delete a student
  const deleteStudent = async (id) => {
    try {
      const response = await fetch(`${host}/students/delete/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem('token')
        },
      });
      const json = response.json();
      setStudents(json);
    } catch (error) {
      console.error("Error fetching all students:", error);
      // Handle the error, you might want to set an error state or display a message to the user
    }
    console.log('Deleting note with ' + id)
    const newStudentsData = students.filter((student) => { return student._id !== id })
    setStudents(newStudentsData)
  }

  // Edit a student
  // const editStudent = async (id, name, email, gender, password, address, phone, parentsphone, photo, documentid, role, accountStatus) => {
  //   try {
  //     const response = await fetch(`${host}/students/update/${id}`, {
  //       method: "PATCH",
  //       headers: {
  //         "Content-Type": "application/json",
  //         "auth-token": localStorage.getItem('token'), // Replace with your actual auth token
  //       },
  //       body: JSON.stringify({ name, email, gender, password, address, phone, parentsphone, photo, documentid, role, accountStatus }),
  //     });
  //     const json = await response.json();
  //     let newStudents = JSON.parse(JSON.stringify(students))
  //     // Logic to edit in client
  //     for (let index = 0; index < students.length; index++) {
  //       const element = newStudents[index];
  //       if (element._id === id) {
  //         element.name = name;
  //         element.email = email;
  //         element.gender = gender;
  //         element.password = password;
  //         element.address = address;
  //         element.phone = phone;
  //         element.parentsphone = parentsphone;
  //         element.photo = photo;
  //         element.documentid = documentid;
  //         element.role = role;
  //         element.accountStatus = accountStatus;
  //         break;
  //       }
  //     }
  //     setStudents(newStudents)
  //   } catch (error) {
  //     console.error("Error editing student:", error);
  //     // Handle the error
  //   }
  // };


  // Edit a student
  const editStudent = async (id, formData) => {
    try {
      const response = await fetch(`${host}/students/update/${id}`, {
        method: "PATCH",
        body: formData, // formData includes both files and text fields
        headers: {
          // "Content-Type": "multipart/form-data" will be set automatically with the correct boundary
          "auth-token": localStorage.getItem('token'), // Ensure your auth-token is correctly set
        },
      });

      const json = await response.json(); // Assuming the server responds with JSON that includes the updated student
      if (!response.ok) {
        const errorMessage = json.message || "An error occurred while updating the student.";
        throw new Error(errorMessage);
      }

      // Update local state with the response data
      setStudents(prevStudents => {
        return prevStudents.map(student => {
          if (student._id === id) {
            // Assuming your API returns the updated student object in 'json.updatedStudent'
            // Replace the entire student object with the updated data from the server
            return { ...json.updatedStudent };
          }
          return student; // For all other students, return them as they were
        });
      });

      // Return success status and the updated student data
      return { success: true, updatedStudent: json.updatedStudent, message: "Student updated successfully" };

    } catch (error) {
      console.error("Error updating student:", error);
      return { success: false, message: "Failed to update student.", error: error.message };

    }
  };

  // Edit a student's account status
  const editStudentAccountStatus = async (id, accountStatus) => {
    try {
      const response = await fetch(`${host}/students/toggleacStatus/${id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem('token'), // Replace with your actual auth token
        },
        body: JSON.stringify({ accountStatus }), // Send only the accountStatus field
      });

      if (response.ok) {
        // Assuming the response is successful, you can update the client-side state
        let newStudents = JSON.parse(JSON.stringify(students));

        for (let index = 0; index < students.length; index++) {
          const element = newStudents[index];
          if (element._id === id) {
            element.accountStatus = accountStatus; // Update only the accountStatus field
            break;
          }
        }

        setStudents(newStudents);
      } else {
        console.error("Error editing student account status:", response.statusText);
        // Handle the error
      }
    } catch (error) {
      console.error("Error editing student status:", error);
      // Handle the error
    }
  };


   // ============== Attendance Helper to check if seat is expired ==============
   const hasExpiredSeat = (student) => {
    return student.seatAssigned?.some(seat => {
      const validityDate = new Date(seat.validityDate);
      return validityDate.getTime() < Date.now();
    });
  };

  // ============== Determine a single student's attendance status ==============
  // We'll look for a record in `attendanceData`
  const getAttendanceStatus = (studentId) => {
    const record = attendanceData.find(att => att.studentId === studentId);
    if (!record) return "absent";  // If no record, treat as absent
    // Otherwise could be "Online" or "Offline" depending on your DB
    return record.status;  
  };

  // ============== Recompute filterCounts whenever students or attendanceData changes ==============
  useEffect(() => {
    const newCounts = {
      all: students.length,
      active: 0,
      inactive: 0,
      hasBooking: 0,
      expired: 0,
      online: 0,
      offline: 0,
      absent: 0,
    };

    students.forEach(student => {
      // Active/inactive
      if (student.accountStatus) newCounts.active++;
      else newCounts.inactive++;

      // Bookings
      if (student.seatAssigned && student.seatAssigned.length > 0) {
        newCounts.hasBooking++;
      }
      // Expired seat check
      if (hasExpiredSeat(student)) {
        newCounts.expired++;
      }

      // Attendance (using getAttendanceStatus)
      const status = getAttendanceStatus(student._id);
      if (status === "Online") newCounts.online++;
      else if (status === "Offline") newCounts.offline++;
      else newCounts.absent++;
    });

    setFilterCounts(newCounts);
  }, [students, attendanceData]);

  return (
    <StudentContext.Provider value={{ students, fetchAttendanceData, getAttendanceStatus, filterCounts, addStudent, deleteStudent, editStudent, getAllStudents, editStudentAccountStatus, getOneStudent }}>
      {props.children}
    </StudentContext.Provider>
  )
}

export default StudentState




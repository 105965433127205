export const getCurrentTime = () => {
    return new Date().toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true, // AM/PM format
    });
};

export const getCurrentDate = () => {
    return new Intl.DateTimeFormat('en-GB', {
        day: '2-digit',
        month: 'short', // "Feb"
        year: 'numeric' // "2025"
    }).format(new Date());
};

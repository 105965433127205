import React, { useEffect, useState } from 'react';
//import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { convertSlotToTimings } from '../utils/Utilsfunc';
import { isExpiringSoon } from '../utils/Utilsfunc';
import { getCurrentTime, getCurrentDate } from '../utils/DateTimeUtils';
import { useNavigate } from 'react-router-dom';


const AttendanceModal = ({ show, onClose }) => {
    const [time, setTime] = useState(getCurrentTime());
    const [date, setDate] = useState(getCurrentDate());
    const [attendanceCode, setAttendanceCode] = useState('');
    const [studentData, setStudentData] = useState(null);
    const [checkInCount, setCheckInCount] = useState(0);
    const [checkOutCount, setCheckOutCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const host = process.env.REACT_APP_BACKEND_URL;
    const history = useNavigate()

    useEffect(() => {
        const token = localStorage.getItem('token');
        const role = localStorage.getItem('role');

        if (!token) {
            history("/login");
            return;
        }

        if (role !== 'Admin' && role !== 'Superadmin' && role !== 'Attendanceofficer') {
            history("/account");
        }
    }, []); // Runs only once on mount

    useEffect(() => {

        const handlePopstate = () => {
            // Prevent navigating away using the back/forward buttons
            window.history.pushState(null, '', window.location.pathname);
        };

        const handleBeforeUnload = (event) => {
            // Block page reload
            event.preventDefault();
            event.returnValue = ""; // Required for Chrome
        };

        if (show) {
            // Push a new state to the history stack
            window.history.pushState(null, "", window.location.pathname);

            // Add event listeners
            window.addEventListener("popstate", handlePopstate);
            window.addEventListener("beforeunload", handleBeforeUnload);
        }

        // Cleanup: Remove event listeners when the modal closes or the component unmounts
        return () => {
            if (show) {
                window.removeEventListener("popstate", handlePopstate);
                window.removeEventListener("beforeunload", handleBeforeUnload);
            }
        };

    }, [show]);
    // Render nothing if modal is closed
    // Timer-----------------------------------
    useEffect(() => {
        const timer = setInterval(() => {
            setTime(getCurrentTime());
            setDate(getCurrentDate());
        }, 1000); // Update every second

        return () => clearInterval(timer); // Cleanup when unmounting
    }, []);

    //-----------------------------------

    // Fetch Student Data
    const fetchStudentData = async () => {
        if (!attendanceCode) return;
        setLoading(true);
        setError('');

        try {
            const response = await fetch(`${host}/students/getstudent/${attendanceCode}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "auth-token": localStorage.getItem('token'), // Authorization token
                }

            });
            const data = await response.json();

            if (!response.ok) {
                setError(data.error || "Failed to fetch student data");
                setStudentData(null);
            } else {
                setStudentData(data.student);
                checkAttendanceStatus(attendanceCode);
            }
            console.log("checkInCount:", checkInCount, "checkOutCount:", checkOutCount);
        } catch (err) {
            setError("Error fetching student data");
        } finally {
            setLoading(false);
        }
    };

    // Fetch Attendance Status
    const checkAttendanceStatus = async (code) => {
        try {
            const response = await fetch(`${host}/attendance/checkin-status/${code}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "auth-token": localStorage.getItem('token'),
                }
            });

            // If no response or an error occurs, return an empty attendance record
            if (!response.ok) {
                setCheckInCount(0);
                setCheckOutCount(0);
                return;
            }

            const data = await response.json();

            // Handle case where no attendance record exists
            if (!data || !data.checkInCount) {
                setCheckInCount(0);
                setCheckOutCount(0);
                return;
            }

            setCheckInCount(data.checkInCount || 0);
            setCheckOutCount(data.checkOutCount || 0);

        } catch (err) {
            console.error("Error fetching attendance status", err);
            setCheckInCount(0);
            setCheckOutCount(0);
        }
    };

    // Handle Check-In
    const handleCheckIn = async () => {
        setSuccessMessage('');
        setError('');

        try {
            const response = await fetch(`${host}/attendance/checkin`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "auth-token": localStorage.getItem('token'),
                },
                body: JSON.stringify({ attendanceCode })
            });
            const data = await response.json();

            const checkInTime = new Date().toLocaleTimeString('en-US', {
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: true
            });

            if (!response.ok) {
                setError(data.error || "Check-in failed.");
            } else {
                setSuccessMessage(`✅ Successfully checked in at ${checkInTime}!`);
                //setCheckInCount(prev => prev + 1); // Increment check-in count
                //setCheckOutCount(0); // Reset checkout count
                setLoading(true);

                // Reset form after 3 seconds
                setTimeout(() => {
                    resetForm();
                }, 3000);
            }
        } catch (err) {
            setError("Error during check-in.");
        } finally {
            setLoading(true);
        }
    };

    // Handle Check-Out
    const handleCheckOut = async () => {
        setSuccessMessage('');
        setError('');

        try {
            const response = await fetch(`${host}/attendance/checkout`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "auth-token": localStorage.getItem('token'),
                },
                body: JSON.stringify({ attendanceCode })
            });
            const data = await response.json();

            const checkOutTime = new Date().toLocaleTimeString('en-US', {
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: true
            });

            if (!response.ok) {
                setError(data.error || "Check-out failed.");
            } else {
                setSuccessMessage(`✅ Successfully Checked Out at ${checkOutTime}!`);
                //setCheckOutCount(prev => prev + 1); // Increment check-out count
                setLoading(true)

                // Reset form after 3 seconds
                setTimeout(() => {
                    resetForm();
                }, 3000);
            }
        } catch (err) {
            setError("Error during check-out.");
        } finally {
            setLoading(true);
        }
    };

    // Check if any validity date is nearing 5 days
    const isSubscriptionExpiring = studentData?.seatAssigned?.some(seat => {
        const validityDate = new Date(seat.validityDate);
        const today = new Date();
        const diffDays = Math.ceil((validityDate - today) / (1000 * 60 * 60 * 24));
        return diffDays <= 5;
    });

    // Reset form and data
    const resetForm = () => {
        setAttendanceCode('');
        setStudentData(null);
        setCheckInCount(0);
        setCheckOutCount(0);
        setSuccessMessage('');
        setError('');
        setLoading(false);
    };

    if (!show) return null;

    return (

        <div>
            {/* Modal Backdrop with Close on Click Outside */}
            <div
                className="modal fade show gradient-bg-bluebrown"
                style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}
                onClick={onClose} // Close modal when clicking outside
            >
                {/* Modal Dialog (fullscreen-sm-down) */}
                <div
                    className="modal-dialog modal-dialog-centered modal-fullscreen"

                    onClick={(e) => e.stopPropagation()} // Prevent click propagation to backdrop
                >
                    <div className='container'>
                        <div className="modal-content background-glass">
                            {/* Modal Header */}
                            <div className="modal-header">
                                <span className="modal-title  text-center text-white">{date}</span>
                                <h5 className="modal-title text-white">Mark your attendance</h5>
                                <span className="modal-title text-center text-white">{time}</span>
                                {/* <button
                                    type="button"
                                    className="btn-close"
                                    onClick={onClose} // Close modal when X is clicked
                                    aria-label="Close"
                                ></button> */}
                            </div>

                            {/* Modal Body */}
                            <div className="modal-body mt-4">
                                <div className='col-7 mx-auto'>

                                    <div className="input-group mb-2">
                                        <input type="number" className="form-control" placeholder="Enter your attendance code" onChange={(e) => setAttendanceCode(e.target.value)} value={attendanceCode} aria-label="Attendance Code" aria-describedby="button-addon2" />
                                        <button className="btn btn-primary" type="button" id="button-addon2" onClick={fetchStudentData}>Submit</button>
                                    </div>
                                    <div className='text-center'>
                                        {error && <p className="text-danger text-center bg-white d-inline-block fw-bold ps-2 pe-2">{error}</p>}
                                        {successMessage && <p className="text-success text-center bg-white d-inline-block fw-bold ps-2 pe-2">{successMessage}</p>}
                                    </div>
                                    {studentData && (
                                        <>

                                            <div className='d-flex justify-content-center'>
                                                {checkInCount === checkOutCount && (
                                                    <button className='me-3 btn btn-lg btn-primary d-flex' style={{ fontSize: "1.75rem", padding: "15px 30px" }} onClick={handleCheckIn} disabled={loading} type='button'>
                                                        <svg className='me-1 pt-1' fill='#fff' xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 384 512"><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" /></svg>
                                                        <span>Check In</span>
                                                    </button>
                                                )}
                                                {checkInCount > checkOutCount && (
                                                    <button className='btn btn-lg btn-primary' style={{ fontSize: "1.75rem", padding: "15px 30px" }} onClick={handleCheckOut} disabled={loading} type='button'>
                                                        <svg className='me-2 pb-1' fill='#fff' xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 640 512"><path d="M208 96a48 48 0 1 0 0-96 48 48 0 1 0 0 96zM123.7 200.5c1-.4 1.9-.8 2.9-1.2l-16.9 63.5c-5.6 21.1-.1 43.6 14.7 59.7l70.7 77.1 22 88.1c4.3 17.1 21.7 27.6 38.8 23.3s27.6-21.7 23.3-38.8l-23-92.1c-1.9-7.8-5.8-14.9-11.2-20.8l-49.5-54 19.3-65.5 9.6 23c4.4 10.6 12.5 19.3 22.8 24.5l26.7 13.3c15.8 7.9 35 1.5 42.9-14.3s1.5-35-14.3-42.9L281 232.7l-15.3-36.8C248.5 154.8 208.3 128 163.7 128c-22.8 0-45.3 4.8-66.1 14l-8 3.5c-32.9 14.6-58.1 42.4-69.4 76.5l-2.6 7.8c-5.6 16.8 3.5 34.9 20.2 40.5s34.9-3.5 40.5-20.2l2.6-7.8c5.7-17.1 18.3-30.9 34.7-38.2l8-3.5zm-30 135.1L68.7 398 9.4 457.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L116.3 441c4.6-4.6 8.2-10.1 10.6-16.1l14.5-36.2-40.7-44.4c-2.5-2.7-4.8-5.6-7-8.6zM550.6 153.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L530.7 224 384 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l146.7 0-25.4 25.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l80-80c12.5-12.5 12.5-32.8 0-45.3l-80-80zM392 0c-13.3 0-24 10.7-24 24l0 48c0 13.3 10.7 24 24 24s24-10.7 24-24l0-48c0-13.3-10.7-24-24-24zm24 152c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 16c0 13.3 10.7 24 24 24s24-10.7 24-24l0-16zM392 320c-13.3 0-24 10.7-24 24l0 16c0 13.3 10.7 24 24 24s24-10.7 24-24l0-16c0-13.3-10.7-24-24-24zm24 120c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 48c0 13.3 10.7 24 24 24s24-10.7 24-24l0-48z" /></svg>
                                                        <span className='lh-1'>Check Out</span>
                                                    </button>
                                                )}
                                            </div>

                                        </>
                                    )}

                                </div>

                                {studentData && (
                                    <>
                                        <h5 className='text-center text-white mb-4'>Your subscription overview</h5>
                                        <div className="card col-7 mb-4 mx-auto">

                                            <div className="card-body">
                                                <h5 className="card-title text-center">{studentData.name}</h5>
                                                <p className="card-text text-center">Please verify the subscription expiration date. If it is approaching or expired, kindly renew.</p>
                                            </div>
                                            <ul className="list-group list-group-flush mb-2">

                                                <li className="list-group-item d-flex justify-content-center">
                                                    <span className='mr-auto'><span>UID: </span><span className='fw-bold'>{studentData.uid}</span></span>
                                                </li>



                                                {studentData.seatAssigned.length > 0 ? (
                                                    studentData.seatAssigned.map((seat, index) => (
                                                        <li key={index} className="list-group-item d-flex flex-wrap justify-content-between align-items-center">
                                                            <span className='flex-fill text-center p-2'>Seat No: {seat.seatNumber}</span>  <span className='flex-fill text-center p-2'>Slot: {convertSlotToTimings(seat.slot)}</span>  <span className='flex-fill text-center p-2'><span>{isExpiringSoon(seat.validityDate) === "expired" ? "Expired: " : " Expires On: "} </span><span className={`fw-bold ${isExpiringSoon(seat.validityDate) === "expired" ? "text-danger" : isExpiringSoon(seat.validityDate) ? "text-warning" : ""}`}>{seat.validityDate}</span></span>
                                                        </li>

                                                    ))
                                                ) : (
                                                    <li className='list-group-item'><p>No booked shifts</p></li>
                                                )}
                                            </ul>


                                        </div>
                                    </>
                                )}

                                {/* <div className='col-7 mx-auto'>
                                    <h4 className='text-center text-white'>Attendance</h4>
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="d-flex justify-content-center">
                                                <Calendar
                                                    onChange={setDate}
                                                    value={date}
                                                    className="border rounded p-2"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                            </div>

                            {/* Modal Footer */}
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={resetForm} // Close modal when button is clicked
                                >Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AttendanceModal;
import React, { useState } from 'react';
import Sidedash from '../uicomponents/Sidedash'
import AttendanceModal from '../modals/AttendanceModal';

const Attendance = () => {
    const [showModal, setShowModal] = useState(false);
    const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
    return (
        <div className="container-fluid">
            <div className="row">
                <Sidedash />

                <div className="col-md-9 pt-3">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className='text-center'>Attendance</h2>
                           
                        <div className="d-flex justify-content-center align-items-center">
                        <button type="button " onClick={handleShowModal}  data-bs-target="#staticBackdrop" className="btn btn-lg btn-success">Open Attendance Page</button>
                        </div>
                        </div>

                        <AttendanceModal show={showModal} onClose={handleCloseModal} />

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Attendance